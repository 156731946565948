.App {
  text-align: center;
}

.App-logo {
  height: 50px;
  width: auto;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background: linear-gradient(90deg, honeydew 0%, white 50%, honeydew 100%);
}

form {
  background-color: white;
}

@media screen and (min-width: 320px) and (max-width: 350px) {
  .insideForm {
    width: 300px;
  }
}
@media screen and (min-width: 351px) and (max-width: 480px) {
  .insideForm {
    width: 330px;
  }
}
@media screen and (min-width: 481px) {
  .insideForm {
    width: 450px;
  }
}